<template>
    <div class="page-warp min-page invoice-page gray-page">
        <van-form v-model="submitForm" ref="submitForm">
            <van-cell :title="parms.title" />
            <div class="row page-model-title">1/6发票类型
            </div>
            <van-field
                readonly
                clickable
                name="picker"
                v-model="invoiceTypeName"
                label="发票类型"
                placeholder="请选择发票类型"
                />
            <div class="row page-model-title">2/6发票抬头</div>
            <van-field
                readonly
                v-model="submitForm.invoiceName"
                label="单位名称"
                placeholder="请输入公司名称"/>
            <van-field
                readonly
                v-model="submitForm.invoiceTaxpayerNo"
                label="税号"
                placeholder="请输入税号"/>
            <van-field
                readonly
                v-model="submitForm.invoiceAddress"
                label="单位地址"
                placeholder="请输入单位地址"/>
            <van-field
                readonly
                v-model="submitForm.invoicePhone"
                label="电话"
                placeholder="请输入电话"/>
            <van-field
                readonly
                v-model="submitForm.invoiceBank"
                label="开户银行"
                placeholder="请输入开户银行"/>
            <van-field
                readonly
                v-model="submitForm.invoiceCardNo"
                label="开户账号"
                placeholder="请输入开户账号"/>
            <div class="row page-model-title">3/6开票内容</div>
            <van-field
                readonly
                v-model="submitForm.invoiceClassName"
                label="开票项目"
                placeholder="请输入开票项目"
                />
            <van-field
                readonly
                v-model="invoiceRateText"
                label="税率"
                placeholder="请输入税率" />
            <van-field
                readonly
                v-model="submitForm.productCount"
                label="数量"
                placeholder="请输入数量"/>
            <van-field
                readonly
                v-model="submitForm.afterTaxFeeUnit"
                label="含税单价"
                placeholder="请输入含税单价"/>
            <van-field
                readonly
                v-model="submitForm.productSpecification"
                label="规格型号"
                placeholder="请输入规格型号"/>
            <van-field
                readonly
                v-model="submitForm.productUnit"
                label="计算单位"
                placeholder="请输入单位"/>
            <van-field
                readonly
                v-model="submitForm.afterTaxFeeAmount"
                label="含税总额"
                placeholder="单价*数量"/>
            <van-field
                readonly
                v-model="submitForm.taxFeeAmount"
                label="税额"
                placeholder="含税总额/1.03*税率"/>
            <van-field
                readonly
                v-model="submitForm.serviceFee"
                label="平台服务费"
                placeholder="请输入平台服务费"/>
            <div class="row page-model-title">4/6送票选择</div>
            <van-field
                readonly
                clickable
                name="picker"
                v-model="mailWayName"
                label="发票递送方式"
                placeholder="请选择发票递送方式"
                />
            <van-cell 
                v-if="submitForm.mailWay == 2"
                :title="submitForm.recipientAddress?submitForm.recipientAddress : '新增收件信息'"
                :label="submitForm.recipientName+'     '+submitForm.recipientPhone" />
        </van-form>
    </div>
</template>
<script>
import { findById } from '@/api/invoice'
import { invoiceType,invoiceClass,mailWay } from '@/api/dic'
export default {
    name:'invoiceDetail',
    data() {
        return {
            loading: false,
            showLX:false,
            invoiceList: [],
            invoiceLoop:[],

            showXM:false,
            invoiceClassList: [],
            invoiceClassLoop:[],

            showMW:false,
            mailWayList:[],
            mailWayLoop:[],
            submitForm:{
                id:'',
                customerName:'',
                customerId:'',
                invoiceType:'',
                invoiceTypeName:'',
                productUnit:'',
                invoiceTaxpayerNo:'',
                invoiceAddress:'',
                invoicePhone:'',
                invoiceBank:'',
                invoiceCardNo:'',
                invoiceClass:'',
                invoiceClassName:'',
                invoiceRate:'',
                productCount:'',
                afterTaxFeeUnit:'',
                productSpecification:'',
                productUnit:'',
                afterTaxFeeAmount:'',
                taxFeeAmount:'',
                serviceFee:'',
                mailWay:'',
                mailWayName:'',
                recipientAddress:'',
                recipientName:'',
                recipientPhone:'',
            },
            parms:{
                id:'',
                title:'',
            },
            invoiceTypeName:'',
            // invoiceClassName:'',
            mailWayName:'',
            invoiceRateText:'',
        };
    },
    created(){
        this.$nextTick(()=>{
            this.parms.title = this.$route.query.title;
            this.parms.id = this.$route.query.id;
            this.findAll();
        })
    },
    mounted(){
    },
    methods: {
        //   通过id查询订单信息
        async findAll() {
            let res = await findById(this.parms.id);
            this.submitForm = res.data;
            // this.invoiceRateText = this.submitForm.invoiceRate+'%'
            this.invoiceRateText = this.submitForm.invoiceRateStr
            this.getInvoiceType();
            // this.getInvoiceClass();
            this.getMailWay();
        },
        updateFeeAmount(){
            if(this.submitForm.afterTaxFeeUnit > 0 && this.submitForm.productCount > 0){
                this.submitForm.afterTaxFeeAmount = this.mul(Number(this.submitForm.afterTaxFeeUnit),Number(this.submitForm.productCount)).toFixed(2)
            }
        },
        updateTaxFeeAmount(){
            if(this.submitForm.afterTaxFeeAmount > 0 && this.submitForm.invoiceRate){
                if(this.submitForm.invoiceRate == 0.03){
                    this.submitForm.taxFeeAmount = this.mul(Number(this.submitForm.afterTaxFeeAmount)/1.03,0.03).toFixed(2);
                }else{
                    this.submitForm.taxFeeAmount = 0
                }
            }
        },
        getInvoiceType(){
            invoiceType().then(res => {
                res.data.map(item=>{
                    if(item.dictValue == this.submitForm.invoiceType){
                        this.invoiceTypeName = item.dictLabel;
                    }
                })
            });
        },
        /*getInvoiceClass(){
            invoiceClass().then(res => {
                res.data.map(item=>{
                    if(item.dictValue == this.submitForm.invoiceClass){
                        this.invoiceClassName = item.dictLabel;
                    }
                })
            });
        },*/
        getMailWay(){
            mailWay().then(res => {
                res.data.map(item=>{
                    if(item.dictValue == this.submitForm.mailWay){
                        this.mailWayName = item.dictLabel;
                    }
                })
            });
        },
        mul(a, b) {
                var c = 0,
                d = a.toString(),
                e = b.toString();
            try {
                c += d.split(".")[1].length;
            } catch (f) {}
            try {
                c += e.split(".")[1].length;
            } catch (f) {}
            return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
        }
    },
};
</script>
<style lang="less" scoped>
.invoice-page { padding-bottom:10px;}
.page-model-title {
    text-align: left; line-height: 40px; font-size: 14px; color: #080808;
    box-sizing: border-box; padding:0px 10px;
    span {
        color: #278BF9; float: right;
    }
}
.fxed-bottom {
    position: fixed;
    bottom:0px; left: 0px;
    height: 50px; background: #F4F6FA; border-top:1px solid#ccc;
    span {
        display: inline-block; height: 50px; line-height: 50px; font-size: 16px;
        box-sizing: border-box;
    }
    .f-total {
        width: 65%;float: left;
        background: rgba(39, 139, 249, 0.06); color:#000;
        padding-left:10px;
    }
    .f-sub {
        width: 35%; float: right;
        background: #278BF9; color:#fff;
        text-align: center;
    }
}
</style>